@import "bootstrap";
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  background: #1e1a20;
}

#root {
  width: 100%;
}
.section {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  font-family: "Cabin", sans-serif;
  color: #ffeded;
  text-transform: uppercase;
  font-size: 7vmin;
  padding-left: 10%;
  padding-right: 10%;
}

section:nth-child(odd) {
  justify-content: flex-end;
}

#nav {
  background: transparent;
}

#navLinks {
  justify-content: flex-end;
}

#self {
  width: 60%;
  height: auto;
  @media (min-width: 576px) {
    width: 100%;
    height: 50%;
  }
  @media (min-width: 768px) {
    width: 90%;
    height: 55%;
  }
  @media (min-width: 992px) {
    width: 70%;
    height: auto;
  }
}

.card {
  position: relative;
  height: 500px;
  background: black
}

.github {
  margin: 5px;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.card-body,
.card-text {
  color: white;
}

#footer,
#about {
  position: relative;
}
a {
  cursor: pointer;
  z-index: 1;
}
